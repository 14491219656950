<div *ngIf="organization">
  <div *ngIf="show == 'invitations' && organization.Invitations?.length > 0">
    <div class="table-container">
      <table mat-table #table [dataSource]="inviteDataSource"
             multiTemplateDataRows>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let invitation">
            <strong>{{getName(invitation)}}</strong> {{getUserName(invitation)}} ({{getStatus(invitation)}})
          </td>
        </ng-container>

        <!-- Role Column -->
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>
          <td mat-cell *matCellDef="let invitation">
            {{getRoleForInvitation(invitation)}}
          </td>
        </ng-container>

        <!-- Action column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let invitation">
            <div>
              <button mat-stroked-button *ngIf="!isMember(invitation)" value="invite" (click)="inviteAgain(invitation)">Invite Again</button>&nbsp;
              <button mat-stroked-button value="delete" (click)="deleteInvitation(invitation)">Delete</button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
        <tr mat-row *matRowDef="let panel; columns: columnsToDisplay;"
            class="example-element-row">
        </tr>
      </table>
    </div>
  </div>
  <div *ngIf="show == 'members' && memberList && memberList.length > 0">
    <div class="table-container mat-elevation-z0">
      <table mat-table #table [dataSource]="memberDataSource"
             multiTemplateDataRows>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let member"> {{member.FirstName}} {{member.LastName}} ({{member.UserName}})</td>
        </ng-container>

        <!-- 2FA Column -->
        <ng-container matColumnDef="2fa">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Two-factor Authentication </th>
          <td mat-cell *matCellDef="let member"><mat-icon font-size="12">{{get2FA(member) ? 'gpp_good' : 'gpp_bad'}}</mat-icon></td>
        </ng-container>


        <!-- Action column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let member">
            <div>
              <button mat-stroked-button [matMenuTriggerFor]="roleMenu">
                <span>{{role(member)}}</span>
                <mat-icon>arrow_drop_down</mat-icon>
              </button>&nbsp;
              <button *ngIf="!isOwner(member)" mat-stroked-button (click)="deleteMember(member)">Remove</button>
              <mat-menu #roleMenu="matMenu">
                <ng-template matMenuContent>
                  <button *ngIf="!isOwner(member)" mat-menu-item (click)="changeRole(member, 6)">Moderator</button>
                  <button mat-menu-item (click)="changeRole(member, 5)">Owner</button>
                  <button mat-menu-item (click)="changeRole(member, 19)">Guest</button>
                </ng-template>
              </mat-menu>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
        <tr mat-row *matRowDef="let panel; columns: columnsToDisplay;"
            class="example-element-row">
        </tr>
      </table>
    </div>
  </div>
</div>
