<mat-list [formGroup]="formGroup">
  <div *ngIf="system">
    <app-user-org-search [users]="users" label="Search Users/Organizations"></app-user-org-search>
  </div>
  <mat-list-item class="msg-hist-list0">
    <div matLine class="msg-hist-list0-container">
      <div class="row-flex">
        <app-advanced-timings placeholder="From" [(value)]="startDate" [timezone]="timezone"></app-advanced-timings>
        <app-advanced-timings placeholder="To" label="-" [(value)]="endDate" [timezone]="timezone"></app-advanced-timings>
      </div>
      &nbsp;
      <mat-form-field appearance="outline" class="msg-hist-filtByCountry-field">
        <mat-label>Filter by Country</mat-label>
        <mat-select formControlName="Country">
          <mat-option value="">All Countries</mat-option>
          <mat-option *ngFor="let country of countries" [value]="country">{{country.Country}}</mat-option>
        </mat-select>
      </mat-form-field>&nbsp;
      <mat-form-field appearance="outline" class="msg-hist-filtByNum-field">
        <mat-label>Filter by a number</mat-label>
        <input matInput placeholder="Number" formControlName="Number">
      </mat-form-field>&nbsp;
      <mat-form-field appearance="outline" class="msg-hist-msgType-field">
        <mat-label> Message Type </mat-label>
        <mat-select formControlName="MessageType">
          <mat-option value="0">All</mat-option>
          <mat-option value="1">SMS</mat-option>
          <mat-option value="2">WhatsApp</mat-option>
        </mat-select>
      </mat-form-field>&nbsp;
      <mat-form-field appearance="outline" class="msg-hist-msgType-field">
        <mat-label> Direction </mat-label>
        <mat-select formControlName="Direction">
          <mat-option value="0">Both</mat-option>
          <mat-option value="1">Inbound</mat-option>
          <mat-option value="2">Outbound</mat-option>
        </mat-select>
      </mat-form-field>&nbsp;
      <mat-form-field appearance="outline">
        <mat-label>Receipt Status</mat-label>
        <mat-select formControlName="Status" #statuses multiple>
          <mat-option value="-1" (click)="selectStatus('-1')">All</mat-option>
          <mat-option value="0" (click)="selectStatus('0')">Delivered</mat-option>
          <mat-option value="1" (click)="selectStatus('1')">Submitted to Carrier</mat-option>
          <mat-option value="2" (click)="selectStatus('2')">Buffered For Retry</mat-option>
          <mat-option value="3" (click)="selectStatus('3')">Failed</mat-option>
          <mat-option value="4" (click)="selectStatus('4')">Unknown</mat-option>
          <mat-option value="5" (click)="selectStatus('5')">System Error</mat-option>
          <mat-option value="6" (click)="selectStatus('6')">Black Listed</mat-option>
          <mat-option value="7" (click)="selectStatus('7')">Destination Busy</mat-option>
          <mat-option value="8" (click)="selectStatus('8')">Number Unroutable</mat-option>
          <mat-option value="9" (click)="selectStatus('9')">Destination Unavailable</mat-option>
          <mat-option value="10" (click)="selectStatus('10')">Blocked</mat-option>
          <mat-option value="11" (click)="selectStatus('11')">Success</mat-option>
          <mat-option value="12" (click)="selectStatus('12')">Invalid Destination Number</mat-option>
          <!--mat-option value="13" (click)="selectStatus('13')">Inbound</!--mat-option-->
          <mat-option value="14" (click)="selectStatus('14')">On Stop</mat-option>
          <mat-option value="15" (click)="selectStatus('15')">Read</mat-option>
          <mat-option value="16" (click)="selectStatus('16')">Invalid Source Number</mat-option>
          <mat-option value="17" (click)="selectStatus('17')">Undelivered</mat-option>
          <mat-option value="18" (click)="selectStatus('18')">Content Related Error</mat-option>
          <mat-option value="19" (click)="selectStatus('19')">No Longer Active</mat-option>
          <mat-option value="20" (click)="selectStatus('20')">Network Error</mat-option>
          <mat-option value="21" (click)="selectStatus('21')">Age Restriction</mat-option>
          <mat-option value="22" (click)="selectStatus('22')">Insufficient Funds</mat-option>
          <mat-option value="23" (click)="selectStatus('23')">Regulation</mat-option>
          <mat-option value="24" (click)="selectStatus('24')">Throttled</mat-option>
          <mat-option value="25" (click)="selectStatus('25')">Equipment Permanent Error</mat-option>
          <mat-option value="26" (click)="selectStatus('26')">Equipment Temporary Error</mat-option>
          <mat-option value="27" (click)="selectStatus('27')">Rejected</mat-option>
          <mat-option value="28" (click)="selectStatus('28')">Invalid Receipt Request</mat-option>
          <mat-option value="29" (click)="selectStatus('29')">Unreachable</mat-option>
          <mat-option value="30" (click)="selectStatus('30')">Spam</mat-option>
          <mat-option value="31" (click)="selectStatus('31')">Queued for Submission</mat-option>
          <mat-option value="32" (click)="selectStatus('32')">Expired</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-list-item>
  <mat-list-item>
    <mat-form-field appearance="outline" class="msg-hist-filtByMsg-field">
      <mat-label>Filter by message content</mat-label>
      <input matInput placeholder="Content" formControlName="Content">
    </mat-form-field>
  </mat-list-item>
  <mat-list-item *ngIf="survey == undefined">
    Use Metadata for a more accurate search:
  </mat-list-item>
</mat-list>
<app-metadata-table *ngIf="survey == undefined" #metadataTable [metadata]="metadata"></app-metadata-table>
<mat-list>
  <mat-list-item>
    <button mat-raised-button color="primary" (click)="searchForMessages()">Search</button>&nbsp;
    <button mat-raised-button color="primary" (click)="exportMessages()">Export</button>&nbsp;
    <span *ngIf="total > 0">
      <strong>A total of {{total}} message{{total > 1 ? 's were ' : ' was '}} found.</strong>
    </span>
    <span *ngIf="total == 0">
      <strong>No messages were found.</strong>
    </span>
  </mat-list-item>
</mat-list>
<app-file-process *ngIf="process?.ProcessId > 0" [process]="process" (completed)="onFileProcessCompleted($event)"></app-file-process>
<div *ngIf="isExporting" class="loading-container">
  <div class="loading-content">
    <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
    <div class="spinner-text"> Exporting...</div>
  </div>
</div>
<div class="outer-table-container">
  <div class="inbox-table-container custom-scroll-bar">
    <mat-table [dataSource]="messages" mat-table>
      <ng-container matColumnDef="TranDate">
        <mat-header-cell *matHeaderCellDef> Tran Date </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.DateAdded | date:'medium'}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Account">
        <mat-header-cell *matHeaderCellDef> Account </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Account}} ({{element.OwnerId}}) </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Survey">
        <mat-header-cell *matHeaderCellDef> Survey </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Survey?.Name}} <span *ngIf="isAdminUser || isImpersonating()">({{element.Survey?.SurveyID}})</span>  </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Country">
        <mat-header-cell *matHeaderCellDef> Country </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.MobileZone?.Country}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Source">
        <mat-header-cell *matHeaderCellDef> Source </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Source}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Destination">
        <mat-header-cell *matHeaderCellDef> Destination </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Destination}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Inbound">
        <mat-header-cell *matHeaderCellDef> Direction </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Inbound ? 'Inbound' : 'Outbound'}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Message">
        <mat-header-cell *matHeaderCellDef> Message </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Message}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Type">
        <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{getType(element)}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Segments">
        <mat-header-cell *matHeaderCellDef> Seg </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.Segments}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Status">
        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.ReceiptStatusMessage}} </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
      <mat-row class="msg-hist-element-row" *matRowDef="let row; columns: displayedColumns; let i = index;" (click)="showMessageDetails(i, row)"></mat-row>
    </mat-table>
    <mat-paginator [pageSize]="pageSize" [length]="total"></mat-paginator>
  </div>
</div>
<ng-template #messageDetail>
  <div class="msg-hist-dtl-container">
    <div class="user-details-dialog-container">
      <div class="leftFloat">
        <h3>Message Detail</h3>
      </div>
      <div class="rightFloat">
        <button *ngIf="index > 0" mat-icon-button matTooltip="Previous" matTooltipPosition="below" (click)="previousMessage()">
          <mat-icon class="user-details-dialog-close-icon">arrow_back</mat-icon>
        </button>
        <button *ngIf="index + 1 < messages.length" mat-icon-button matTooltip="Next" matTooltipPosition="below" (click)="nextMessage()">
          <mat-icon class="user-details-dialog-close-icon">arrow_forward</mat-icon>
        </button>
        <button mat-icon-button matDialogClose="no" matTooltip="Close" matTooltipPosition="below">
          <mat-icon class="user-details-dialog-close-icon">close</mat-icon>
        </button>
      </div>
    </div>
    <div class="clearBoth mt-5"></div>
    <div class="custom-scroll-bar compose-msg-main-div">
      <div class="msg-hist-dtl-content">
        <div class="user-details-invitation-container">
          <div><strong>Tran Id:</strong>&nbsp;</div><div>{{data.TranID}} (MS{{data.MessageManagerId}})</div>
        </div>
        <div class="user-details-invitation-container">
          <div>
            <strong>
              Transaction Date:
            </strong>&nbsp;
          </div>
          <div>
            {{data.DateAdded | date:'medium'}}
          </div>
          <div *ngIf="isAdminUser || isImpersonating()">&nbsp;(<strong>TranDate: </strong></div>
          <div *ngIf="isAdminUser || isImpersonating()">&nbsp;{{data.TranDate | date:'medium'}})</div>
        </div>
        <div *ngIf="isAdminUser || isImpersonating()" class="user-details-invitation-container">
          <div>
            <strong>
              Account:
            </strong>&nbsp;
          </div><div>
            {{data.Account}} ({{data.OwnerId}})
          </div>
        </div>
        <div class="user-details-invitation-container">
          <div>
            <strong>
              Country:
            </strong>&nbsp;
          </div><div>
            {{data.MobileZone?.Country}} ({{data.MobileZone?.CountryCode}})
          </div>
        </div>
        <div *ngIf="data.SurveyId > 0">
          <div class="user-details-invitation-container">
            <div>
              <strong>
                Survey:
              </strong>&nbsp;
            </div><div>
              {{data.Survey?.Name}}
            </div>
          </div>
        </div>
        <div class="user-details-invitation-container">
          <div>
            <strong>
              From:
            </strong>&nbsp;
          </div><div>
            {{data.Source}}
          </div>
        </div>
        <div class="user-details-invitation-container">
          <div>
            <strong>
              To:
            </strong>&nbsp;
          </div><div>
            {{data.Destination}}
          </div>
        </div>
        <div class="user-details-invitation-container">
          <div>
            <strong>
              Direction:
            </strong>&nbsp;
          </div><div>
            {{data.Inbound ? 'Inbound' : 'Outbound'}}
          </div>
        </div>
        <div>
          <div>
            <strong>
              Message:
            </strong>&nbsp;
          </div><div class="alert alert-primary" role="alert">
            <div class="msg-hist-msg-content-div">
              {{data.Message}}
            </div>
          </div>
        </div>
        <div class="user-details-invitation-container">
          <div>
            <strong>
              Segments:
            </strong>&nbsp;
          </div>
          <div>
            {{data.Segments}}
          </div>
        </div>
        <div class="user-details-invitation-container">
          <div>
            <strong>
              Lookup:
            </strong>&nbsp;
          </div>
          <div>
            {{data.Lookup}}
          </div>
        </div>
        <div class="user-details-invitation-container">
          <div>
            <strong>
              Test:
            </strong>&nbsp;
          </div>
          <div>
            {{data.Test}}
          </div>
        </div>
        <div class="user-details-invitation-container">
          <div>
            <strong>
              Number Type:
            </strong>&nbsp;
          </div>
          <div>
            {{data.NumberTypeString}}
          </div>
        </div>
        <div class="user-details-invitation-container">
          <div>
            <strong>
              Status:
            </strong>&nbsp;
          </div><div>
            {{data.ReceiptStatusMessage}}
          </div>
        </div>
        <div *ngIf="data.Metadata?.length > 0">
          <div>
            <strong>
              Metadata:
            </strong>&nbsp;
          </div>
        </div>
        <div *ngFor="let metadata of data.Metadata" class="user-details-invitation-container">
          <div>
            <strong>
              {{metadata.Name}}:
            </strong>&nbsp;
          </div>
          <div>
            {{metadata.Value}}
          </div>
        </div>
        <div>
          <div *ngIf="isAdminUser || isImpersonating()" class="user-details-invitation-container">
            <div>
              <strong>
                Supplier:
              </strong>&nbsp;
            </div><div>
              {{data.Supplier}}
            </div>
          </div>
          <div class="user-details-invitation-container">
            @if(isAdminUser || isImpersonating()) {
            <strong>
              Supplier ReceiptID &amp; Status:
            </strong>&nbsp;
            } @else {
            <strong>
              ReceiptID &amp; Status:
            </strong>
            }
            <div>
              <div *ngFor="let receipt of data.Receipts">
                @if(receipt?.ReceiptID == data?.Receipt?.ReceiptID) {
                <strong>{{getReceipt(receipt)}}&nbsp;{{receipt?.StatusForReport}}&nbsp;({{receipt?.LastUpdated | date:'medium'}})</strong>
                } @else {
                {{getReceipt(receipt)}}&nbsp;{{receipt?.StatusForReport}}&nbsp;({{receipt?.LastUpdated | date:'medium'}})
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
