<div>
  <div class="fieldRow">
    <div>
      <button mat-raised-button [disabled]="filter == 7" color="primary" (click)="createUser()">Create New User</button>&nbsp;&nbsp;
    </div>
    <div>
      <button mat-stroked-button [matMenuTriggerFor]="userTypeMenu">
        <span>{{filterType}}</span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>&nbsp;&nbsp;
      <mat-menu #userTypeMenu="matMenu">
        <button (click)="filterBy(1)" mat-menu-item>Live Users</button>
        <button (click)="filterBy(2)" mat-menu-item>Suspended Users</button>
        <button (click)="filterBy(3)" mat-menu-item>Unconfirmed Users</button>
        <button (click)="filterBy(4)" mat-menu-item>Deleted Users</button>
        <button (click)="filterBy(5)" mat-menu-item>Template Users</button>
        <button (click)="filterBy(6)" mat-menu-item>Admin Users</button>
        <button (click)="filterBy(7)" mat-menu-item>Organizations</button>
      </mat-menu>
    </div>
    <div>
      <mat-form-field appearance="outline">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Filter" #input>
      </mat-form-field>
    </div>
    <div *ngIf="tempUsers | async">
      &nbsp;{{filter == 7 ? organizations.length : Users.length}} {{filter == 7 ? 'Organization(s)' : 'User(s)' }}
    </div>
    <div *ngIf="tempUsers | async">
      &nbsp;<button mat-raised-button color="primary" (click)="export()">Export</button>
    </div>
    <div>
      &nbsp;<button mat-raised-button color="primary" (click)="import()">Import</button>
    </div>
  </div>
  <div>
    <br />
  </div>
  <table *ngIf="tempUsers | async; else loading" mat-table [dataSource]="dataSource" class="mat-elevation-z1">
    <ng-container matColumnDef="UserID">
      <th mat-header-cell *matHeaderCellDef> ID. </th>
      <td mat-cell *matCellDef="let element"> {{element.UserID}} </td>
    </ng-container>

    <ng-container matColumnDef="BusinessType">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element">
        <div style="margin-top:10px">
          <mat-icon *ngIf="isTemplate(element)" [matBadge]="templateType(element)" matBadgeSize="small" [matBadgeColor]="templateType(element) == 'T' ? 'accent' : 'warn'">{{businessType(element)}}</mat-icon> <mat-icon *ngIf="!isTemplate(element)">{{businessType(element)}}</mat-icon>
        </div>
      </td>
    </ng-container>

    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Org(Owner - Member) </th>
      <td mat-cell *matCellDef="let organization"> {{organization.OrganizationId}}({{organization.OwnerId}} - {{organization.MemberId}}) </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let organization"> {{organization.Name}} </td>
    </ng-container>

    <!-- Created Column -->
    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
      <td mat-cell *matCellDef="let organization"> {{organization.Created | date:'short': '' : translate.currentLang}} </td>
    </ng-container>

    <!-- Respondent count Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
      <td mat-cell *matCellDef="let organization"> {{organization.Description}} </td>
    </ng-container>

    <!-- Owner Element Column -->
    <ng-container matColumnDef="owner">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Owner </th>
      <td mat-cell *matCellDef="let organization"> {{owner(organization)}} </td>
    </ng-container>

    <!-- Role Element Column -->
    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>
      <td mat-cell *matCellDef="let organization"> {{role(organization)}} </td>
    </ng-container>

    <!-- Action column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let organization">
        <div>
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item [routerLink]='["/edituser", organization.OwnerId, "user" ]'>
              <mat-icon>edit</mat-icon>
              <span>Edit</span>
            </button>
            <button *ngIf="role(organization) != 'Snubbed' && !isOwner(organization)" mat-menu-item (click)="leave(organization)">
              <mat-icon>person_remove</mat-icon>
              <span>Leave</span>
            </button>
          </mat-menu>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="CreateDate">
      <th mat-header-cell *matHeaderCellDef> Created </th>
      <td mat-cell *matCellDef="let element"> {{element.CreatedDate  | date:'short' : '' : translate.currentLang}} </td>
    </ng-container>

    <ng-container matColumnDef="LastLoggedIn">
      <th mat-header-cell *matHeaderCellDef> Last Logged In </th>
      <td mat-cell *matCellDef="let element"> {{element.LastLoggedIn  | date:'medium'}} </td>
    </ng-container>

    <ng-container matColumnDef="Tfa">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> 2FA </th>
      <td mat-cell *matCellDef="let member"><mat-icon font-size="12">{{get2FA(member) ? 'gpp_good' : 'gpp_bad'}}</mat-icon></td>
    </ng-container>

    <ng-container matColumnDef="UserName">
      <th mat-header-cell *matHeaderCellDef> Username </th>
      <td mat-cell *matCellDef="let element"> {{element.UserName}} </td>
    </ng-container>

    <ng-container matColumnDef="FirstName">
      <th mat-header-cell *matHeaderCellDef> Firstname </th>
      <td mat-cell *matCellDef="let element"> {{element.FirstName}} </td>
    </ng-container>
    <ng-container matColumnDef="LastName">
      <th mat-header-cell *matHeaderCellDef> Lastname </th>
      <td mat-cell *matCellDef="let element"> {{element.LastName}} </td>
    </ng-container>
    <ng-container matColumnDef="EmailAddress">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let element"> <div matBadge="!" [matBadgeHidden]="element.IsConfirmed" matBadgeSize="small" matBadgePosition="before" [matTooltip]="element.IsConfirmed ? '' : 'This account has not been verified'">{{element.EmailAddress}} </div></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row class="users-element-row" *matRowDef="let row; columns: displayedColumns;" (click)="edit(row)" (contextmenu)="onContextMenu($event, row)"></tr>
    <tr class="users-element-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>
  <div #contextMenuTrigger="matMenuTrigger" [ngStyle]="{position:'fixed',top:contextMenuPosition.y,left:contextMenuPosition.x}" [matMenuTriggerFor]="contextMenu"></div>
  <mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-user="user">
      <button mat-menu-item (click)="edit(user)">
        <mat-icon>edit</mat-icon>
        <span>Edit User</span>
      </button>
      <button mat-menu-item (click)="impersonate(user)">
        <mat-icon>people_alt</mat-icon>
        <span>Impersonate</span>
      </button>
      <button mat-menu-item (click)="signin(user)">
        <mat-icon>login</mat-icon>
        <span>Signin</span>
      </button>
    </ng-template>
  </mat-menu>
</div>

<ng-template #loading>
  <div class="loading-container" style="width:100%">
    <span class="loading-content">
      <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text" style="width:75%"> Loading {{filter == 7 ? 'organizations' : 'users'}}...</div>
    </span>
  </div>
  <br /><br />
</ng-template>

