import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MissionService } from '../../MissionService';
import { SurveyModel, SurveyService } from '../../survey.Service';

@Component({
  selector: 'app-survey-properties',
  templateUrl: './survey-properties.component.html',
  styleUrls: ['./survey-properties.component.css']
})
export class SurveyPropertiesComponent {

  subscription: Subscription;
  survey: SurveyModel;
  id: number = 0;

  constructor(private surveyService: SurveyService,
    public translate: TranslateService,
    private missionService: MissionService,
    private actRoute: ActivatedRoute, private router: Router) {
    this.id = Number(this.actRoute.snapshot.params.id);
    this.subscription = missionService.missionAnnounced$.subscribe(
      mission => {
        if (mission === "Back") {
          this.router.navigate(["/surveydashboard"]);
        }
      });

    this.router = router;
    this.surveyService.getSurveyDetail(this.id, false).subscribe(result => {
      this.survey = result;
    });
  }
}
