<div class="reset-password-main-div">
  <div *ngIf="formReady" class="reset-password-card" [@fadeInOnEnter]>
    <form class="reset-password-form column-flex" [formGroup]="formGroup" (ngSubmit)="resetPassword()">
      <div>
        <h3 class="txt-align-center">Reset Password</h3>
      </div>
      <div *ngIf="displayError">
        <div class="alert alert-danger" role="alert">
          {{errorMsg}}
        </div>
      </div>
      <div *ngIf="displayForm == true" class="column-flex">
        <div>
          <mat-form-field appearance="outline">
            <mat-label>New Password</mat-label>
            <input matInput type="password" placeholder="Enter new password" formControlName="newPassword">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline">
            <mat-label>Confirm Password</mat-label>
            <input matInput type="password" placeholder="Confirm your password" formControlName="confirmPassword">
          </mat-form-field>
        </div>
        <div *ngIf="!loggingIn">
          <button mat-raised-button type="submit" class="mt-5" color="primary">Change Password</button>
        </div>
        <div *ngIf="loggingIn" [@fadeInOnEnter] [@fadeOutOnLeave] style="margin:5px auto; width:50%; text-align:center;align-content:center">
          <div style="display:flex;flex-flow:row;vertical-align:central">
            <div style="margin-right:10px">
              <mat-spinner [diameter]="25"></mat-spinner>
            </div>
            <div style="font-size:1rem">
              Logging into your account
            </div>
          </div>
        </div>
        <div *ngIf="showCaptcha" [@fadeInOnEnter] [@fadeOutOnLeave] style="display:flex;flex-flow:column">
          <div style="margin: 5px auto">
            <re-captcha (resolved)="resolvedCaptcha($event)" (errored)="erroredCaptcha($event)" errorMode="handled" [siteKey]="siteKey"></re-captcha>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
