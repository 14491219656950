import { Component } from '@angular/core';

@Component({
  selector: 'app-nav-title',
  templateUrl: './nav-title.component.html',
  styleUrl: './nav-title.component.css'
})
export class NavTitleComponent {

}
