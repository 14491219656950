import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { options, baseUrl } from "../environments/environment";
import { Observable, timeout } from "rxjs";
import { Metadata } from "./authentication.Service";
import { SurveyModel } from "./survey.Service";
import { UserProfile } from "./user.Service";
import { plainToClass } from "class-transformer";

export class Message {
  TranID: number = 0;
  DateAdded: Date = new Date();
  Source: string = "";
  Destination: string = "";
  TranDate: Date = new Date();
  Message: string = "";
  Inbound: boolean = false;
  CustomerID: number = 0;
  SurveyId: number = 0;
  OwnerID: number = 0;
  Processed: boolean = false;
  ReceiptLevel: number = 0;
  ReceiptId: number = 0;
  ReceiptStatus: number = 0;
  ReceiptStatusMessage: string = "";
  Segments: number = 0;
  CountryID: number = 0;
  TranlogID: number = 0;
  Supplier: string = "";
  MessageManagerID: number = 0;
  WindowId: number = 0;
  MessageType: number = 0;
  Test: boolean = false;
  Lookup: boolean = false;
  Carrier: string = "";
  Reachable: boolean = false;
  NumberTypeId: number = 0;
  IsReceipt: boolean = false;
  Receipt: ReceiptModel = new ReceiptModel();
  Receipts: ReceiptModel[] = [];
  MobileZone: MobileZoneInfo = new MobileZoneInfo();
  Survey: SurveyModel = new SurveyModel();
}

export class MessageStatsSearchModel {
  From: Date = new Date();
  To: Date = new Date();
  UserIds: number[] = [];
  GatewayName: string = "";
  SurveyId: number = 0;
  Statuses: number[] = [];
  IncludeSurveys: boolean = false;
  IncludeClickThru: boolean = false;
}

export class TranLogStatsModel {
  GatewayName: string = "";
  OwnerId: number = 0;
  Account: string = "";
  Country: string = "";
  SurveyId: number = 0;
  Survey: string = "";
  DateAdded: Date = new Date();
  Date: string = "";
  DateOnly: string = "";
  ReceiptStatus: number = 0;
  ReceiptStatusDescription: string = "";
  Segments: number = 0;
  Messages: number = 0;
}

export class TranlogUserStatsModel {
  Account: string = "";
  Country: string = "";
  Survey: string = "";
  DateAdded: Date = new Date();
  Date: string = "";
  DateOnly: string = "";
  ReceiptStatusDescription: string = "";
  Segments: number = 0;
  Messages: number = 0;
}

export class TranCategory {
  type: string = "";
  hierachy: string = "";
  constructor(type: string, hierachy: string) {
    this.type = type;
    this.hierachy = hierachy;
  }
}

export class GlobalStopList {
  GlobalStopListId: number = 0;
  TranId: number = 0;
  Created: Date = new Date();
  Amended: Date = new Date();
  Source: string = "";
  Destination: string = "";
  OwnerID: number = 0;
  KeywordID: number = 0;
}

export class TranLevel {
  type: string = "";
  hierachy: string = "";
  level: string = "";
  parent: string = "";
  constructor(type: string, hierachy: string, level: string, parent: string) {
    this.type = type;
    this.hierachy = hierachy;
    this.level = level;
    this.parent = parent;
  }
}

export class TranValue {
  type: string = "number";
}

export class TranLogResults {
  Gateway: TranCategory;
  Account: TranLevel;
  Date: TranLevel;
}

export class TranLogStatsResults {
  results: TranlogUserStatsModel[] = [];

  constructor(results: TranLogStatsModel[]) {
    this.buildResultsTable(results);
  }

  buildResultsTable(results: TranLogStatsModel[]) {
    if (results.length == 0) {
      return;
    }

    let offset = this._columns.length;

    // Build column list
    for (let i = 0; i < results.length; i++) {
      results[i] = plainToClass(TranLogStatsModel, results[i]);
      if (!this._columns.includes(results[i].ReceiptStatusDescription)) {
        this._columns.push(results[i].ReceiptStatusDescription);
      }

      results[i].Date = " " + results[i].DateAdded.toLocaleString();
      results[i].DateOnly = " " + results[i].Date.split("T")[0];
      let us = new TranlogUserStatsModel();
      us.Account = results[i].Account;
      us.Country = results[i].Country;
      us.DateAdded = results[i].DateAdded;
      us.DateOnly = results[i].DateOnly;
      us.Date = results[i].Date;
      us.ReceiptStatusDescription = results[i].ReceiptStatusDescription;
      us.Segments = results[i].Segments;
      us.Messages = results[i].Messages;
      us.Survey = results[i].Survey;
      this.results.push(us);
      if (results[i].Account.includes("(")) {
        continue;
      }

      results[i].Account = results[i].Account + "(" + results[i].OwnerId + ")";
      results[i].Survey = results[i].Survey + "(" + results[i].SurveyId + ")";
    }

    /*// Build grid of gateway results
    let gwResults = new TranLogResults(results[0].GatewayName, 0, "", new Date(), this._columns.length);
    let accountResults = new TranLogResults(results[0].GatewayName, results[0].OwnerId, results[0].Account, new Date(), this._columns.length);
    let dateResults = new TranLogResults(results[0].GatewayName, results[0].OwnerId, results[0].Account, results[0].DateAdded, this._columns.length);
    let gwDate = results[0].DateAdded;
    let account = results[0].Account;
    for (let i = 0; i < results.length; i++) {
      const index = this._columns.indexOf(results[i].ReceiptStatusDescription) - offset;
      if (gwDate != results[i].DateAdded) {
        accountResults.Results.push(dateResults);
        dateResults = new TranLogResults(results[i].GatewayName, results[i].OwnerId, results[i].Account, results[i].DateAdded, this.columns.length);
        gwDate = results[i].DateAdded;
      }

      if (account != results[i].Account) {
        gwResults.Results.push(accountResults);
        accountResults = new TranLogResults(results[i].GatewayName, results[i].OwnerId, results[i].Account, new Date(), this._columns.length);
      }

      if (gwResults.GatewayName != results[i].GatewayName) {
        this._gatewayResults.push(gwResults);
        gwResults = new TranLogResults(results[i].GatewayName, 0, "", new Date(), this._columns.length);
      }

      gwResults.Segments[index] += results[i].Segments;
      dateResults.Segments[index] += results[i].Segments;
      accountResults.Segments[index] += results[i].Segments;
    }

    accountResults.Results.push(dateResults);
    gwResults.Results.push(accountResults);
    this._gatewayResults.push(gwResults);*/
  }

  get gatewayResults(): TranLogResults[] {
    return this._gatewayResults
  }
  private _gatewayResults: TranLogResults[] = [];

  get columns(): string[] {
    return this._columns;
  }
  private _columns: string[] = [];
}

export class MessageSummaryModel {
  TotalMessages: number = 0;
  Messages: Message[] = [];

  constructor() {
    this.TotalMessages = 0;
    this.Messages = [];
  }
}

export class ReceiptModel {
  ReceiptID: number = 0;
  GatewayReceiptID: string = "";
  SurveyID: number = 0;
  ReceiptIndicator: number = 0;
  ReceiptLevel: number = 0;
  MessageManagerID: number = 0;
  Created: Date = new Date();
  LastUpdated: Date = new Date();
  Status: number = 0;
  StatusForReport: string = "";
  GeneralStatus: string = "";
}

export class MessageModel {
  Source: string = "";
  Destination: string = "";
  Message: string = "";
  Reply: boolean = false;
  Test: boolean = false;
  Lookup: boolean = false;
  KeyId: number = 0;
  Type: string = "";
}

export class MobileCountryInfo {
  CountryCode: string = "";
  Country: string = "";
  Territory: string = "";
  TerritoryOrService: string = "";
  TimeZone: TimeZoneInfo = new TimeZoneInfo();
  DayLightSaving: string = "";
  Codes: string[] = [];
}

export class MobileZoneInfo {
  Code: string = "";
  CountryCode: string = "";
  Country: string = "";
  Territory: string = "";
  TerritoryOrService: string = "";
  TimeZone: TimeZoneInfo = new TimeZoneInfo();
  DayLightSaving: string = "";
}

export class TimeZoneInfo {
  BaseUtcOffset: object = null;
  DaylightName: string = "";
  DisplayName: string = "";
  HasIanId: boolean = false;
  Id: string = "";
  Local: object = null;
  StandardName: string = "";
  SupportsDaylightSavingTime: boolean = false;
  Utc: object = null;
}

export class MessageSearchModel {
  UserId: number;
  Page: number;
  PageSize: number;
  SurveyId: number;
  Start: Date;
  End: Date;
  Countries: string[];
  Number: string;
  Metadata: Metadata[];
  Direction: number;
  Statuses: number[];
  Content: string;
  MessageType: number;
  GatewayName: string;
  constructor(userId: number, page, pageSize, surveyId: number, start: Date, end: Date, countries: string[], number: string, metadata: Metadata[], direction: number, statuses: number[],
              content, messageType: number,
              gatewayName: string) {
    this.UserId = userId;
    this.Page = page;
    this.PageSize = pageSize;
    this.SurveyId = surveyId;
    this.Start = start;
    this.End = end;
    if (countries) {
      this.Countries = countries;
    }
    else {
      this.Countries = [];
    }

    if (number) {
      this.Number = number;
    }
    else {
      this.Number = "";
    }

    this.Metadata = metadata;
    this.Direction = direction;
    this.Statuses = statuses;
    this.Content = content;
    this.MessageType = messageType;
    this.GatewayName = gatewayName;
  }
}

export class GatewayTranSummary {
  OwnerId: number = 0;
  SurveyId: number = 0;
  When: Date;
  Count: number = 0;
  Survey: SurveyModel;
  UserProfile: UserProfile;
}

export class TranLogSummary {
  GatewayName: string = "";
  Inbound: boolean = false;
  ReceiptStatus: number = 0;
  StatusString: string = "";
  When: Date;
  Count: number = 0;
}

@Injectable()
export class MessageService {
  constructor(
    private http: HttpClient) {
  }

  fileOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'text/plain, */*',
      'Cache-Control': 'no-cache',
    },
    responseType: 'blob'
  };

  public checkGlobalStopList(destination: string): Observable<any> {
    return this.http.get(baseUrl + 'Message/CheckGlobalStopList?number=' + destination, options);
  }

  public unStopNumber(source: string, destination: string): Observable<any> {
    return this.http.patch(baseUrl + 'Message/UnstopNumber?source=' + source + '&destination=' + destination, options);
  }

  public getTranlogStats(model: MessageStatsSearchModel): Observable<any> {
    return this.http.put(baseUrl + 'Message/GetTranLogStatus', model, options);
  }

  public getGatewaySummary(groupBy: string): Observable<any> {
    return this.http.get(baseUrl + 'Message/GetGatewayTranSummary?groupBy=' + groupBy, options);
  }

  public getCountries(): Observable<any> {
    return this.http.get(baseUrl + 'Message/GetCountries', options);
  }

  public get(userId: number, page: number, pageSize: number, surveyId: number, start: Date, end: Date, countries: string[], number: string, metadata: Metadata[], direction: number, statuses: number[], content: string, messageType: number, gatewayName: string): Observable<any> {
    const model: MessageSearchModel = new MessageSearchModel(userId, page, pageSize, surveyId, start, end, countries, number, metadata, direction, statuses, content, messageType, gatewayName);
    return this.http.put(baseUrl + "Message/Get", JSON.stringify(model), options);
  }

  public GetTotal(userId: number, page: number, pageSize: number, surveyId: number, start: Date, end: Date, countries: string[], number: string, metadata: Metadata[], direction: number, statuses: number[], content: string, messageType: number, gatewayName: string): Observable<any> {
    const model: MessageSearchModel = new MessageSearchModel(userId, page, pageSize, surveyId, start, end, countries, number, metadata, direction, statuses, content, messageType, gatewayName);
    return this.http.put(baseUrl + "Message/GetTotal", JSON.stringify(model), options);
  }

  public export(userId: number, surveyId: number, start: Date, end: Date, countries: string[], number: string, metadata: Metadata[], direction: number, statuses: number[], content: string, messageType: number, gatewayName: string): Observable<any> {
    const model: MessageSearchModel = new MessageSearchModel(userId, 0, 0, surveyId, start, end, countries, number, metadata, direction, statuses, content, messageType, gatewayName);
    let headersWithTimeout = new HttpHeaders().append("Content-Type", "application/json");
    headersWithTimeout.append("timeout", "120000");
    return this.http.patch(baseUrl + "Message/Export", JSON.stringify(model), {
      responseType: "blob",
      headers: new HttpHeaders().append("Content-Type", "application/json")
    });
  }

  public exportRequest(userId: number, surveyId: number, start: Date, end: Date, countries: string[], number: string, metadata: Metadata[], direction: number, statuses: number[], content: string, messageType: number, gatewayName: string): Observable<any> {
    const model: MessageSearchModel = new MessageSearchModel(userId, 0, 0, surveyId, start, end, countries, number, metadata, direction, statuses, content, messageType, gatewayName);
    return this.http.patch(baseUrl + "Message/ExportRequest", JSON.stringify(model), options);
  }

  public GetReceiptsForMessage(tranId: number): Observable<any> {
    return this.http.get(baseUrl + "Message/GetReceiptsForMessage?tranId=" + tranId, options);
  }

  public getTranLogSummary(): Observable<any> {
    return this.http.get(baseUrl + 'Message/GetTranLogSummary', options)
  }

  public send(message: MessageModel): Observable<any> {
    return this.http.post(baseUrl + "Message/Send", JSON.stringify(message), options);
  }

  public check(message: string): Observable<any> {
    return this.http.patch(baseUrl + 'Message/Check', JSON.stringify(message), options);
  }
}
