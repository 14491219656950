import { HttpHeaders } from "@angular/common/http";
import { InMemoryCache } from "../app/utils";

export const environment = {
  production: true
};
export const options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
export const cintUrl = "https://api.cintworks.net/";

export const version = "7.3 (20250214007)";

let i = "Unknown";
let b = "/api/";
let url = window.location.href.toString();
if (url.startsWith("https://ire")) { i = "Ireland"; b = "https://ire.diysurveys.com/api/"; }
else if (url.startsWith("https://ldn")) { i = "London"; b = "https://ldn.diysurveys.com/api/"; }
else if (url.startsWith("https://sg")) { i = "Singapore"; b = "https://sg.diysurveys.com/api/"; }
else if (url.startsWith("https://staging")) { i = "Staging"; b = "https://staging.diysurveys.com/api/"; }
else if (url.startsWith("https://ams")) { i = "Amsterdam"; b = "https://ams.diysurveys.com/api/"; }
else if (url.startsWith("https://dev")) { i = "Development"; b = "https://dev.diysurveys.com/api/"; }
else if (url.startsWith("https://lbg")) { i = "Lloyds Banking Group (LBG)"; b = "https://lbg.diysurveys.com/api/"; }
else if (url.startsWith("https://diysurveys")) { i = "London"; b = "https://diysurveys.com/api/"; }
export const instance = i;
export const baseUrl = b

export const SQUARE_APPLICATION_ID = "sandbox-sq0idb-jAeCFLLBCI2yNAtoLSP0aA";
export const SQUARE_ACCESS_TOKEN = "EAAAEEJl8yjySR1TMf-20DNWvw4g-LBQZXZMBe4OhdbNJGqDM_KB-DcvB39WuUHd";
export const SQUARE_LOCATION_ID = "LNR8K43REQ9HJ";

export const cache = new InMemoryCache()
