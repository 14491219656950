<div class="flex-stretch-column margin-10">
  <h3>Set up your organization</h3>
  <mat-vertical-stepper linear="true" #stepper>
    <mat-step [stepControl]="firstFormGroup">
      <form [formGroup]="firstFormGroup">
        <ng-template matStepLabel>Organization Details</ng-template>
        <div>
          <p>
            <strong>Organization Name</strong><br />
            This will be the name of the account associated with the organization.
          </p>
          <mat-form-field mat-line appearance="outline" class="halfWidth">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Name" formControlName="Name" (keyup)="checkName($event)">
          </mat-form-field>
          <span mat-line>&nbsp;</span>
          <span mat-line *ngIf="errorMsg !== ''" class="alert alert-danger">{{errorMsg}}</span>
          <span mat-line *ngIf="success === true" class="alert alert-success">This name is available</span>
        </div>
        <div>
          <p><strong>Organization Description</strong></p>
          <mat-form-field mat-line appearance="outline" class="halfWidth">
            <mat-label>Description</mat-label>
            <textarea matInput placeholder="Description" formControlName="Description"></textarea>
          </mat-form-field>
        </div>
        <div>
          <button mat-stroked-button color="primary" matStepperNext>
            Next
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup">
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel>Invite Others to Your Organization</ng-template>
        <invite-people #invites></invite-people>
        <div>
          <button mat-stroked-button matStepperPrevious>
            <span class="color-black">Back</span>
          </button>&nbsp;
          <button mat-raised-button color="primary" (click)="create()">
            Create Organization
          </button>
        </div>
      </form>
    </mat-step>
  </mat-vertical-stepper>
</div>

<ng-template #loading>
  <div class="loading-container">
    <span class="loading-content">
      <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text"> Please Wait...</div>
    </span>
  </div>
</ng-template>

<ng-template #invitationDialog>
  <div class="fullWidth dialog-title">
    <div class="leftFloat">
      <h3>Confirm Invitations</h3>
    </div>
    <div class="rightFloat">
      <button mat-icon-button matDialogClose="no" matTooltip="Close" matTooltipPosition="below">
        <mat-icon class="dialog-close-icon">close</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="invites.list.length > 0" class="clearBoth mt-10">
    <div class="alert alert-success" role="alert">
      <span>Give them the appropriate role in the organization</span>
    </div>
    <div>
      <div *ngFor="let invite of invites.list" class="row-flex-flow">
        <div class="wd-75-per">
          {{invite.UserName}}
        </div>
        <div>
          <mat-form-field appearance="outline" class="width-200">
            <mat-label>Role</mat-label>
            <mat-select [(value)]="invite.Role">
              <mat-option [value]="0">Moderator</mat-option>
              <mat-option [value]="1">Owner</mat-option>
              <mat-option [value]="2">Guest</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div>
        <button mat-raised-button color="primary" (click)="dialogRef.close('yes')">Send Invitation{{invites.list.length > 1 ? 's' : ''}}</button>
      </div>
    </div>
  </div>
  <div *ngIf="invites.list.length == 0" class="clearBoth mt-10">
    <div class="alert alert-success" role="alert">
      <span>Confirm that you wish to create the organization</span>
    </div>
    <div>
      <button mat-raised-button color="primary" (click)="dialogRef.close('yes')">Create Organization</button>
    </div>
  </div>
</ng-template>
