<div class="verify-main-div">
  <div class="verify-card">
    <div>
      <h2>Account Verification</h2>
    </div>
    <div *ngIf="displayError === 1">
      <div class="alert alert-danger" role="alert">
        The link you used is invalid. Please contact support.
      </div>
    </div>
    <div *ngIf="displayError === 0">
      <div class="alert alert-primary" role="alert">
        You have successfully verified your account. Please <a [routerLink]="'/login'">Click Here</a> to login.
      </div>
    </div>
    <div *ngIf="displayError > 1">
      <div class="alert alert-primary" role="alert">
        {{errorMsg}}
      </div>
    </div>
  </div>
</div>
