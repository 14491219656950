<app-newtoolbar toolbarType="signup"></app-newtoolbar>
<div #body class="body" [class]="(isHandset$ | async) ? 'noDisplay' : ''">
  <div class="header" style="width:100%">
    <div class="headerContent">
      <div style="width:50%;margin:0 auto;text-align:center">
        <div *ngIf="enterDetails" [@fadeInOnEnter] [@fadeOutOnLeave] style="display:flex;flex-flow:column">
          <h1><span style="font-size:2rem">Log into your account</span></h1>
          <p>

          </p>
          <form [formGroup]="formGroup" autocomplete="off" style="width:50%;margin: 0 auto">
            <div style="margin:5px auto;width:100%;">
              <div *ngIf="getError() != ''" [@fadeInOnEnter] [@fadeOutOnLeave] class="alert alert-danger" role="alert">
                <div style="font-size:0.75rem">{{getError()}}</div>
              </div>
            </div>
            <div style="width:100%;margin:0 auto;">
              <div style="text-align:left">
                <mat-form-field appearance="outline" class="fullWidth">
                  <mat-label>User&#8204;name</mat-label>
                  <input matInput [readonly]="loggingIn || showCaptcha" required value=" " [id]="usernameControlName" [formControlName]="usernameControlName">
                  <span *ngIf="getError() == ''"></span>
                </mat-form-field>
              </div>
            </div>
            <div style="width:100%;margin:0 auto;">
              <div style="text-align:left">
                <mat-form-field appearance="outline" class="password-field fullWidth">
                  <mat-label>P&#8204;assword</mat-label>
                  <input matInput type="password" autocomplete="new-password" [readonly]="loggingIn || showCaptcha" value=" " [id]="passwordControlName" [formControlName]="passwordControlName">
                </mat-form-field>
                <div style="display:flex;flex-flow:row;width:100%">
                  <div style="font-size:0.60rem;width:100%">
                    <div style="text-align:right">
                      <a routerLink="/forgotpassword">Forgot Password?</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <div *ngIf="!loggingIn && !showCaptcha" style="margin-top:20px">
                <button mat-raised-button type="submit" color="primary" (click)="loginProcess()">LOG IN</button>
              </div>
          </form>
          <div *ngIf="!loggingIn && !showCaptcha" style="margin:5px auto;width:50%;">
            <div class="new-register-or-separator">
              <span>or Log In with</span>
            </div>
            <div style="width: 100%; margin-top:-20px; height: 50px; display: flex; flex-flow: row; align-items: center;">
              <div style="width: 50%; margin: 0 -40px 0 18%;">
                <asl-google-signin-button type="icon" size="medium"></asl-google-signin-button>
              </div>
              <div class="social-login-separator">
              </div>
              <a mat-stroked-button href="#" (click)="logInWithFacebook()" style="width: 50%; border: none; height: 100%; padding-top: 7px;">
                <img src="assets/img/icon-facebook.png" />
              </a>
            </div>
          </div>
        </div>
        <div *ngIf="loggingIn" [@fadeInOnEnter] [@fadeOutOnLeave] style="margin:5px auto; width:50%; text-align:center;align-content:center">
          <div style ="display:flex;flex-flow:row;vertical-align:central">
            <div style="margin-right:10px">
              <mat-spinner [diameter]="25"></mat-spinner>
            </div>
            <div style="font-size:1rem">
              Logging into your account
            </div>
          </div>
        </div>
        <div *ngIf="unconfirmed" [@fadeInOnEnter] [@fadeOutOnLeave] class="alert alert-primary" role="alert">
          <span style="font-size:0.75rem">Your account has not yet been confirmed.</span><br />
            <mat-checkbox (click)="sendConfirmation()">Tick the box if you would like another confirmation email sent to you. </mat-checkbox>&nbsp;&nbsp;
        </div>
        <div *ngIf="showCaptcha" [@fadeInOnEnter] [@fadeOutOnLeave] style="display:flex;flex-flow:column">
          <div style="margin: 5px auto">
            <re-captcha (resolved)="resolvedCaptcha($event)" (errored)="erroredCaptcha($event)" errorMode="handled" [siteKey]="siteKey"></re-captcha>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <app-newfooter></app-newfooter>
  </div>
</div>
<div #body class="body" [class]="(isHandset$ | async) ? '' : 'noDisplay'" style="background-color: white">
  <div style="width:80%;margin:0 auto;text-align:center">
    <div *ngIf="enterDetails" [@fadeInOnEnter] [@fadeOutOnLeave] style="display:flex;flex-flow:column">
      <h1><span style="font-size:2rem">Log into your account</span></h1>
      <p>

      </p>
      <form [formGroup]="formGroup">
        <div style="margin:5px auto;width:100%;">
          <div *ngIf="getError() != ''" [@fadeInOnEnter] [@fadeOutOnLeave] class="alert alert-danger" role="alert">
            <div style="font-size:0.75rem">{{getError()}}</div>
          </div>
        </div>
        <div>
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>Email/Username</mat-label>
            <input matInput placeholder="Email/Username" [readonly]="loggingIn || showCaptcha" [formControlName]="usernameControlName">
            <span *ngIf="getError() == ''"></span>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" class="password-field fullWidth">
            <mat-label>Password</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" placeholder="Password" [readonly]="loggingIn || showCaptcha" [formControlName]="passwordControlName">
            <mat-icon class="reveal" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </mat-form-field>
        </div>
        <div>
          <div style="font-size:0.60rem;width:100%">
            <div style="text-align:right;width:100%">
              <a [routerLink]="'/forgotpassword'">Forgot Password?</a>
            </div>
          </div>
        </div>
        <div *ngIf="!loggingIn && !showCaptcha" style="margin-top:20px">
          <button mat-raised-button type="submit" color="primary" (click)="loginProcess()">LOG IN</button>
        </div>
      </form>
      <div *ngIf="!loggingIn && !showCaptcha" style="margin:5px auto;width:50%;">
        <div class="new-register-or-separator">
          <span>or Log In with</span>
        </div>
        <div style="width: 100%; margin-top:-20px; height: 50px; display: flex; flex-flow: row; align-items: center;">
          <div style="width: 50%; margin: 0 -40px 0 18%;">
            <asl-google-signin-button type="icon" size="medium"></asl-google-signin-button>
          </div>
          <div class="social-login-separator">
          </div>
          <a mat-stroked-button href="#" (click)="logInWithFacebook()" style="width: 50%; border: none; height: 100%; padding-top: 7px;">
            <img src="assets/img/icon-facebook.png" />
          </a>
        </div>
      </div>
    </div>
    <div *ngIf="loggingIn" [@fadeInOnEnter] [@fadeOutOnLeave] style="display:flex;flex-flow:column">
      <h2><span style="font-size:2rem">Logging into your account</span></h2>
      <div style="margin: 5px auto">
        <mat-spinner></mat-spinner>
      </div>
    </div>
    <div *ngIf="unconfirmed" [@fadeInOnEnter] [@fadeOutOnLeave] class="alert alert-primary" role="alert">
      <span style="font-size:0.75rem">Your account has not yet been confirmed.</span><br />
      <mat-checkbox (click)="sendConfirmation()">Tick the box if you would like another confirmation email sent to you. </mat-checkbox>&nbsp;&nbsp;
    </div>
    <div *ngIf="showCaptcha" [@fadeInOnEnter] [@fadeOutOnLeave] style="display:flex;flex-flow:column">
      <div style="margin: 5px auto">
        <re-captcha (resolved)="resolvedCaptcha($event)" (errored)="erroredCaptcha($event)" errorMode="handled" [siteKey]="siteKey"></re-captcha>
      </div>
    </div>
  </div>
  <div class="footer-mobile">
    <app-newfooter></app-newfooter>
  </div>
</div>
<ng-template #loggingInDialog>
  <div class="user-details-profile-image-container" style="width:200px;height:100%;vertical-align:middle;">
    <div class="loading-content">
      <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text"> Logging in...</div>
    </div>
  </div>
</ng-template>
