import { Component } from '@angular/core';
import { SurveyModel, SurveyService } from '../../survey.Service';
import { ActivatedRoute } from '@angular/router';
import { InterviewModel, InterviewService } from '../../question.service';
import { plainToClass } from 'class-transformer';

@Component({
  selector: 'app-export-setup',
  templateUrl: './export-setup.component.html',
  styleUrl: './export-setup.component.css'
})
export class ExportSetupComponent {
  survey: SurveyModel;
  interview: InterviewModel;
  modelChecked: boolean = false;
  id: number;

  constructor(
    private surveyService: SurveyService,
    private interviewService: InterviewService,
    private actRoute: ActivatedRoute
  ) {
    this.id = Number(this.actRoute.snapshot.params.id);
    this.surveyService.getSurveyDetail(this.id, false).subscribe(
      result => {
        this.survey = result;
        this.interviewService.getInterview(this.survey.ScriptID).subscribe(result => {
          if (result === null) {
            this.survey.QuestionCount = 0;
            this.interview = new InterviewModel();
            this.modelChecked = true;
          }
          else {
            this.interview = plainToClass(InterviewModel, result);
            this.interview.checkModel();
           // this.questionTexts = this.interview.textQuestions();
            this.modelChecked = true;
          }
        });

      }
    )
  }
}
