 <div *ngIf="survey" class="custom-scroll-bar">
  <div class="fullHeight">
    <mat-horizontal-stepper linear #stepper class="fullHeight">
      <mat-step *ngIf="displayQuestionnaire" [completed]="hasScript">
        <ng-template matStepLabel><span joyrideStep="designSurveyStep" [stepContent]="designSurveyTourContent">Design Survey</span></ng-template>
        <ng-template #designSurveyTourContent>
          <strong> Design Survey </strong>
          <br />
          <br />
          Step 1: Create and test your survey
        </ng-template>
        <question-editor #step1 [(survey)]="survey"></question-editor>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel><span joyrideStep="selectRespondentStep" [stepContent]="selectRespondentTourContent">Select Respondents</span></ng-template>
        <ng-template #selectRespondentTourContent>
          <strong> Select Respondents </strong>
          <br />
          <br />
          Step 2: Choose respondents and delivery method (SMS, email or WhatsApp)
        </ng-template>
        <app-select-respondents-landing-page #step2 (secondStepEvent)="moveToThirdStep(stepper)" [interview]="interview" [survey]="survey"></app-select-respondents-landing-page>
      </mat-step>
      <mat-step *ngIf="interview || step1" label="Make Live">
        <ng-template matStepLabel #step3><span joyrideStep="surveyLiveStep" [stepContent]="surveyLiveTourContent">Make Survey Live</span></ng-template>
        <ng-template #surveyLiveTourContent>
          <strong> Make Survey Live </strong>
          <br />
          <br />
          Step 3: Schedule and send your survey
        </ng-template>
        <div class="column-flex align-center-flex auto-flex">
          <div *ngIf="panelPricingReady()" class="auto-flex fullWidth">
            <app-panel-pricing #step3Pricing [surveyDetails]="this" [allowEditDelete]="false"></app-panel-pricing>
          </div>
          <div class="txt-align-center">
            <div class="step-3-scheduleType-content">
              <mat-label>Survey will go live: </mat-label>
              <mat-button-toggle-group aria-label="Schedule Type" [value]="scheduleType">
                <mat-button-toggle value="now" (click)="selectNow()">Now</mat-button-toggle>
                <mat-button-toggle value="cron" (click)="openScheduler()">Later</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <div *ngIf="!displayScheduler" class="margin-10">
            <div *ngIf="survey && survey.JobMaster" class="survey-dtlNav-advTime-container">
              <app-advanced-timings [timezone]="timezone" label="" placeholder="Ends" [(value)]="jobMasterEnd"></app-advanced-timings>
            </div>
          </div>
          <div *ngIf="displayScheduler" class="margin-10">
            <div class="survey-dtlNav-schedule-container">
              <app-task-scheduler [schedule]="schedule" (scheduleChange)="scheduleChanged($event)"></app-task-scheduler>
            </div>
          </div>
          <mat-accordion class="survey-dtlNav-more-container">
            <mat-expansion-panel hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  More Options
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div>
                <h3>Interview Timeout</h3>
                <p>
                  You have the ability to increase or decrease the interview time period from 3 days.
                  It allows you to leave an interview open for an individual for a specified period of time after which they will be timed out and unable to complete the survey.
                </p>
                <mat-form-field appearance="outline">
                  <mat-label>Timeout Value</mat-label>
                  <mat-select #timeout (selectionChange)="onTimeoutChange($event)" [value]="survey?.Occurences < 5 ? 4320 : survey?.Occurences">
                    <mat-option [value]="5">5 Minutes</mat-option>
                    <mat-option [value]="10">10 Minutes</mat-option>
                    <mat-option [value]="180">3 Hours</mat-option>
                    <mat-option [value]="306">6 Hours</mat-option>
                    <mat-option [value]="720">12 Hours</mat-option>
                    <mat-option [value]="1440">1 Day</mat-option>
                    <mat-option [value]="2880">2 Days</mat-option>
                    <mat-option [value]="4320">3 Days</mat-option>
                    <mat-option [value]="5760">4 Days</mat-option>
                    <mat-option [value]="7200">5 Days</mat-option>
                    <mat-option [value]="8640">6 Days</mat-option>
                    <mat-option [value]="10040">1 Week</mat-option>
                    <mat-option [value]="20080">2 Weeks</mat-option>
                    <mat-option [value]="30120">3 Weeks</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div>
                <h3>Invite Windows</h3>
                <p>
                  Invite Windows allow you to control (within the schedule) when invites can go out. This is particularly useful when you
                  have SMS going out that take time to send and you only want them to go out within specific windows of time so that
                  it does not contravene country legislation (typically 8am to 8pm is ok) and/or annoy the recipients.
                  Use multiple windows to refine the times for different days and/or multiple times on the same day.<br /><br />
                  Note: For invites using mobile numbers, all times are adjusted for the time zone of the destination country.
                </p>
                <app-window #messageWindows (onChange)="onWindowChange($event)" [model]="windows"></app-window>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <div class="txt-align-center margin-10" *ngIf="displaySendButton">
            <button mat-raised-button [disabled]="step1?.errors?.length > 0 || step3Pricing?.notEnough || !(step3Pricing?.distributionSetup ?? true)" type="button" color="primary" (click)="sendSurvey()">{{sendButtonText}}</button>
          </div>
          <div *ngIf="launch" class="txt-align-center">
            <div>
              <mat-icon>rocket_launch</mat-icon>
              Launching the Survey
            </div>
            <div>
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div>
              <br />
              Please wait or <button mat-raised-button color="primary" (click)="backtoDashboard()">Go Back to the Survey Dashboard</button>
            </div>
          </div>
          <div *ngIf="launched">
            We are taking you to the Delivery Summary...
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div>
