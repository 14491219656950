<div [formGroup]="form" class="adv-ans-opt-container">
  <div *ngIf="displayAnswerOption()">
    <mat-form-field appearance="outline" class="adv-ans-opt-dispAnsOpt-field">
      <mat-icon *ngIf="category" matPrefix>{{icon}}</mat-icon>
      <div class="display-flex">
        <input matInput formControlName="id" [patterns]="customPatterns" mask="A*"/>
      </div>
    </mat-form-field>
  </div>
  <div *ngIf="displayAnswerOption()">&nbsp;</div>
  <div *ngIf="displayFactor()">
    <mat-form-field appearance="outline" class="adv-ans-opt-row adv-ans-opt-dispFactor-field">
      <mat-label>Factor</mat-label>
      <input matInput formControlName="factor" />
    </mat-form-field>
  </div>
  <div *ngIf="displayFactor()">&nbsp;</div>
  <div class="fullWidth">
    <mat-form-field appearance="outline" class="adv-ans-opt-row">
      <mat-icon *ngIf="!isSms && category" matPrefix>{{icon}}</mat-icon>
      <mat-label>
        {{optionTextLabel}}
        <mat-icon *ngIf="category && category.Other">short_text</mat-icon>
        <mat-icon *ngIf="category && category.Fixed">vertical_distribute</mat-icon>
        <mat-icon *ngIf="category && category.Exclusive">done</mat-icon>
      </mat-label>
      <div>
        <textarea matInput formControlName="option" class="scroll"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="10"></textarea>
      </div>
      <mat-icon matSuffix [matMenuTriggerFor]="menu">arrow_drop_down</mat-icon>
    </mat-form-field>
  </div>
  <mat-menu #menu="matMenu">
    <button *ngIf="!isSms" mat-menu-item (click)="richContentEditor(editor)"><mat-icon [style.visibility]="'hidden'">done</mat-icon>Rich Content Editor</button>
    <button *ngIf="shouldDisplay || !shouldDisplay" mat-menu-item (click)="allowTextEntry()"><mat-icon [style.visibility]="category && category.Other ? 'visible' : 'hidden'">done</mat-icon>Allow Text Entry</button>
    <button *ngIf="category" mat-menu-item (click)="fixPosition()"><mat-icon [style.visibility]="category.Fixed ? 'visible' : 'hidden'">done</mat-icon>Fix Position</button>
    <button *ngIf="shouldDisplay && optionType != 1" mat-menu-item (click)="exclusive()"><mat-icon [style.visibility]="category && category.Exclusive ? 'visible' : 'hidden'">done</mat-icon>Exclusive</button>
    <mat-divider *ngIf="category || parameter"></mat-divider>
    <button mat-menu-item *ngIf="category && index > 0" (click)="moveUp()"><mat-icon>arrow_upward</mat-icon>Move Up</button>
    <button mat-menu-item *ngIf="category && index + 1 < count" (click)="moveDown()"><mat-icon>arrow_downward</mat-icon>Move Down</button>
    <button mat-menu-item *ngIf="parameter && index > 0" (click)="moveUp()"><mat-icon>arrow_back</mat-icon>Move Left</button>
    <button mat-menu-item *ngIf="parameter && index + 1 < count" (click)="moveDown()"><mat-icon>arrow_forward</mat-icon>Move Right</button>
    <mat-divider *ngIf="index > 0 || (index + 1) < count"></mat-divider>
    <button mat-menu-item *ngIf="category" (click)="deleteOption()"><mat-icon>clear</mat-icon>Remove Choice</button>
    <button mat-menu-item *ngIf="parameter" (click)="deleteOption()"><mat-icon>clear</mat-icon>Remove Column</button>
  </mat-menu>
  <ng-template #editor>
    <div>
      <div class="rightFloat">
        <button mat-icon-button class="adv-ans-opt-close-icon-button" (click)="close()">
          <mat-icon class="adv-ans-opt-close-icon">close</mat-icon>
        </button>
      </div>
    </div>
    <div class="clearBoth">
      <diy-editor [channel]="model.Interview.Channel" [questions]="model.Interview.reportableQuestions()" formControlName="option"></diy-editor>
    </div>
  </ng-template>
</div>
