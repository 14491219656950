<div [formGroup]="formGroup">
  <div>
    <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="Port">
      <mat-button-toggle [value]="1">Amazon S3 via Ec2 IAM Role</mat-button-toggle>
      <mat-button-toggle [value]="2">Amazon S3 Storage</mat-button-toggle>
      <mat-button-toggle [value]="3">Amazon S3 via AssumeRole</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div>
    <br />
  </div>
  <div *ngIf="accountType() == 2 || accountType() == 3">
    <mat-form-field appearance="outline" class="ftp-setup-host-field">
      <mat-label>Endpoint</mat-label>
      <mat-select formControlName="Host">
        <mat-option *ngFor="let endpoint of endpoints" [value]="endpoint.SystemName">{{endpoint.SystemName}} - {{endpoint.DisplayName}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="accountType() == 2">
    <div>
      <mat-form-field appearance="outline" class="ftp-setup-host-field">
        <mat-label>Access Key ID</mat-label>
        <input matInput placeholder="Access Key ID" formControlName="Username">
      </mat-form-field>&nbsp;
    </div>
    <div>
      <mat-form-field appearance="outline" class="ftp-setup-host-field">
        <mat-label>Secret Access Key</mat-label>
        <input matInput type="password" placeholder="Secret Access Key" formControlName="Password">
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="accountType() == 3">
    <div>
      <mat-form-field appearance="outline" class="ftp-setup-host-field">
        <mat-label>Role ARN</mat-label>
        <input matInput placeholder="Role ARN" formControlName="Username">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" class="ftp-setup-host-field">
        <mat-label>External ID</mat-label>
        <input matInput placeholder="External ID" formControlName="Password">
      </mat-form-field>
    </div>
  </div>
  <div>
    <mat-form-field appearance="outline" class="ftp-setup-un-field">
      <mat-label>Bucket Name</mat-label>
      <input matInput placeholder="Bucket Name" formControlName="Bucket">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline" class="ftp-setup-rmtFolder-field">
      <mat-label>Folder</mat-label>
      <input matInput placeholder="Folder" formControlName="Folder">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline" class="ftp-setup-rmtFolder-field">
      <mat-label>Filename Pattern</mat-label>
      <input matInput placeholder="Filename Pattern" formControlName="File">
    </mat-form-field>
  </div>
  <div>
    <button mat-raised-button color="primary" (click)="testService()">Test Access to S3 Service</button>&nbsp;
    <div *ngIf="testing" class="loading-container">
      <span class="loading-content">
        <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
        <div class="spinner-text"> Testing...</div>
      </span>
    </div>
    <div *ngIf="successes.length > 0" class="mt-10">
      <div class="alert alert-success" role="alert">
        <div *ngFor="let success of successes">
          {{success}}
        </div>
      </div>
    </div>
    <div *ngIf="errors.length > 0" class="mt-10">
      <div class="alert alert-danger" role="alert">
        <div *ngFor="let error of errors">
          {{error}}
        </div>
      </div>
    </div>
  </div>
</div>
