<div>
  <div style="margin-top:5px;display:flex;flex-flow:row;justify-content:space-between;align-items:center;width:100%">
    <div style="margin-left:10px;">
      <h3>Choose the most appropriate ways to collect responses</h3>
    </div>
    <div style="margin-right:10px">
      <mat-button-toggle-group (change)="layoutChange($event)">
        <mat-button-toggle value="detail"><mat-icon>grid_view</mat-icon></mat-button-toggle>
        <mat-button-toggle value="list"><mat-icon>list</mat-icon></mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
</div>
<div style="margin-top:5px;margin-bottom:5px">
  <mat-divider></mat-divider>
</div>
<div *ngIf="layout == 'detail'" class="slctRspndnt-main-div">
  <div *ngFor="let source of finalSource; let i = index" class="slctRspndnt-card-div">
    <a (click)="select(i)">
      <mat-card class="slctRspndnt-secondstep-cards column-flex">
        <div class="slctRspndnt-card-title-container align-center-start-flex row-flex">
          <div class="slctRspndnt-card-title-content">
            <span>{{source.name}}</span>
          </div>
          <div>
            <img mat-card-image [src]="source.img" [alt]="source.name" class="slctRspndnt-card-img">
          </div>
        </div>
        <div class="slctRspndnt-card-description-container">
          <div class="slctRspndnt-card-description-content">
            <span>
              {{source.description}}
            </span>
          </div>
        </div>
      </mat-card>
    </a>
  </div>
</div>
<div *ngIf="layout=='list'">
  <mat-nav-list #list>
    @for (source of finalSource; track source; let i = $index) {
    <mat-list-item (click)="select(i)">
      <mat-icon matListItemIcon>{{source.icon}}</mat-icon>
      <div matListItemTitle>{{source.name}}</div>
      <div matListItemLine>{{source.description}}</div>
    </mat-list-item>
    }
  </mat-nav-list>
</div>
