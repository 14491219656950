<div [formGroup]="formGroup">
  <div style="display:flex;flex-flow:row;background-color:#f6f6f6;border-radius:10px;">
    <div style="padding:16px;border-right: 1px solid #eeeeee">
      <div class="fieldRowWithPadding">
        <div>
          <mat-button-toggle-group formControlName="DateRange" name="dateRange" (change)="setDateRange()">
            <mat-button-toggle value="Today"><mat-icon font-size="12" matTooltip="Today" matTooltipPosition="above">today</mat-icon></mat-button-toggle>
            <mat-button-toggle value="Weekly"><mat-icon font-size="12" matTooltip="Weekly" matTooltipPosition="above">calendar_view_week</mat-icon></mat-button-toggle>
            <mat-button-toggle value="Monthly"><mat-icon font-size="12" matTooltip="Monthly" matTooltipPosition="above">calendar_month</mat-icon></mat-button-toggle>
            <mat-button-toggle value="Range"><mat-icon font-size="12" matTooltip="Date Range" matTooltipPosition="above">date_range</mat-icon></mat-button-toggle>
          </mat-button-toggle-group>&nbsp;
        </div>
        <div>
          <mat-button-toggle-group formControlName="ClickThru" name="clickThru" multiple (change)="setClickThru()">
            <mat-button-toggle value="Include"><mat-icon font-size="12" matTooltip="Include Click Thru" matTooltipPosition="above">ads_click</mat-icon></mat-button-toggle>
          </mat-button-toggle-group>&nbsp;
        </div>
        <div style="margin-top:2px">
          <app-advanced-timings placeholder="From" [(value)]="startDate" [timezone]="timezone"></app-advanced-timings>
        </div>
        <div style="margin-top:2px">
          <app-advanced-timings placeholder="To" label="-" [(value)]="endDate" [timezone]="timezone"></app-advanced-timings>
        </div>
        <div style="margin-top:3px">
          <button mat-raised-button color="primary" (click)="getMessagesWithSearch()">Search</button>&nbsp;
        </div>
      </div>
    </div>
    <div style="border-left: 1px solid #cccccc">
      <div class="fieldRowWithPadding" style="margin-top:16px">
        <div>
          <button [disabled]="getDateRangeType() == 'Range'" mat-mini-fab (click)="moveBack()" matTooltip="Back" matTooltipPosition="above"><mat-icon font-size="12">arrow_back_ios</mat-icon></button>
          <button [disabled]="getDateRangeType() == 'Range'" mat-mini-fab (click)="moveForward()" matTooltip="Forward" matTooltipPosition="above"><mat-icon font-size="12">arrow_forward_ios</mat-icon></button>
        </div>
      </div>
    </div>
    <div style="border-left: 1px solid #cccccc">
      <div class="fieldRowWithPadding" style="margin-top:16px;margin-left:16px">
        <div>
          <mat-button-toggle-group formControlName="ViewType" name="viewType" (change)="setAnalysisMeasure()">
            <!--mat-button-toggle [value]="1"><mat-icon font-size="12">stacked_line_chart</mat-icon></!--mat-button-toggle-->
            <mat-button-toggle [value]="2"><mat-icon font-size="12">stacked_bar_chart</mat-icon></mat-button-toggle>
            <mat-button-toggle [value]="3"><mat-icon font-size="12">grid_on</mat-icon></mat-button-toggle>
          </mat-button-toggle-group>&nbsp;
        </div>
        <div *ngIf="getViewType() == 2">
          <mat-button-toggle-group formControlName="MessageType" name="messageType" (change)="setAnalysisMeasure()">
            <mat-button-toggle value="Segments"><mat-icon font-size="12">segment</mat-icon></mat-button-toggle>
            <mat-button-toggle value="Messages"><mat-icon font-size="12">message</mat-icon></mat-button-toggle>
            <mat-button-toggle value="Percentage"><mat-icon font-size="12">percent</mat-icon></mat-button-toggle>
          </mat-button-toggle-group>&nbsp;
        </div>
        <div *ngIf="getViewType() == 2" style="margin-top:3px">
          <mat-form-field appearance="outline">
            <mat-label>View By</mat-label>
            <mat-select formControlName="Aggregator" placeholder="View By" (selectionChange)="setAnalysisMeasure()">
              <mat-option [value]="1">Time</mat-option>
              <mat-option [value]="2">Country</mat-option>
              <mat-option [value]="3">Account</mat-option>
              <mat-option [value]="4">Survey</mat-option>
              <mat-option *ngIf="isAdminUser" [value]="5">Gateway</mat-option>
            </mat-select>
          </mat-form-field>&nbsp;
        </div>
        <div *ngIf="getViewType() == 3">
          <div cdkDropList (cdkDropListDropped)="drop($event)" style="display:flex;flex-flow:row">
            <mat-button-toggle-group formControlName="Aggregators" multiple (change)="setAggregators()">
              @for (aggregator of aggregators; track aggregator) {
              <mat-button-toggle value="{{aggregator}}" cdkDrag matTooltip="{{aggregator}}" matTooltipPosition="above"><mat-icon>{{getIconForAggregator(aggregator)}}</mat-icon></mat-button-toggle>
              <!--mat-checkbox [formControlName]="aggregator" (click)="setAggregators()">{{aggregator}}</!--mat-checkbox-->
              }
            </mat-button-toggle-group>
          </div>&nbsp;
        </div>
        <div *ngIf="getViewType() == 3">
          &nbsp;
          <mat-button-toggle-group name="viewType" (change)="setAnalysisMeasure()">
            <!--mat-button-toggle [value]="1"><mat-icon font-size="12">stacked_line_chart</mat-icon></!--mat-button-toggle-->
            <mat-button-toggle (click)="expand()" matTooltip="Expand All" matTooltipPosition="above"><mat-icon font-size="12">open_in_full</mat-icon></mat-button-toggle>
            <mat-button-toggle (click)="collaspe()" matTooltip="Collaspe All" matTooltipPosition="above"><mat-icon font-size="12">close_fullscreen</mat-icon></mat-button-toggle>
          </mat-button-toggle-group>&nbsp;
        </div>
      </div>
    </div>
  </div>
</div>
<div style="display:flex;flex-flow:row;width:100%;margin-top:10px">
  <div *ngIf="segments > 0 && getViewType() == 2" style="width:17%">
    <h3>Receipt Status</h3>
    <mat-checkbox #selectAll [checked]="true" (change)="changedAll($event)">Show All ({{getTotalValue()}})</mat-checkbox>
    <mat-divider></mat-divider>
    <div *ngFor="let status of statuses; let i = index">
      <mat-checkbox [checked]="selectedStatuses[i]" (change)="changedStatus($event, i)">
        <span [style]="'background-color:' + getColor(i) + ';width:10px'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        {{status.ReceiptStatusDescription}} ({{getStatValue(i)}})
      </mat-checkbox>
    </div>
  </div>
  <div *ngIf="nothingShowing" style="width:100%;">
    <div class="qns-noquestions-background">
      <div class="qns-noquestions"><mat-icon>notifications_paused</mat-icon><span class="qns-noquestions-span">Nothing happening here. Please check your selection criteria.</span></div>
    </div>
  </div>
  <div *ngIf="showGraph && segments > 0 && getViewType() != 3" style="width:80%;margin-left:10px;margin-bottom:30px">
    <ngx-charts-bar-vertical-stacked *ngIf="chartType == 2" [animations]="false"
                                     [scheme]="colorSets"
                                     [customColors]="myColors"
                                     [results]="results"
                                     [gradient]="gradient"
                                     [xAxis]="showXAxis"
                                     [yAxis]="showYAxis"
                                     [legend]="showLegend"
                                     [legendPosition]="legendPosition"
                                     [showXAxisLabel]="showXAxisLabel"
                                     [showYAxisLabel]="showYAxisLabel"
                                     [xAxisLabel]="xAxisLabel"
                                     [yAxisLabel]="yAxisLabel"
                                     [legendTitle]="legendTitle"
                                     (select)="onSelect($event)"
                                     (activate)="onActivate($event)"
                                     (deactivate)="onDeactivate($event)">
    </ngx-charts-bar-vertical-stacked>
    <ngx-charts-line-chart *ngIf="chartType == 1" [animations]="false"
                           [scheme]="colorSets"
                           [legend]="legend"
                           [showXAxisLabel]="showXAxisLabel"
                           [showYAxisLabel]="showYAxisLabel"
                           [xAxis]="showXAxis"
                           [yAxis]="showYAxis"
                           [xAxisLabel]="xAxisLabel"
                           [yAxisLabel]="yAxisLabel"
                           [results]="results"
                           (select)="onSelect($event)"
                           (activate)="onActivate($event)"
                           (deactivate)="onDeactivate($event)">
    </ngx-charts-line-chart>
  </div>
  <div *ngIf="segments > 0 && getViewType() == 3" style="width:100%">
    <app-pivot-table #pivotTable [toolbar]="true"
                     [width]="'100%'"
                     [height]="500"
                     [report]="pivotResults"
                     (beforetoolbarcreated)="checkToolbar($event)">
      WebDataRocks will appear here
    </app-pivot-table>
  </div>
</div>
